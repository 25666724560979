/* Home.css */

.zoomIn {
    animation: shrink 5s infinite alternate;
  }
  
  @keyframes shrink {
    100% {
      background-size: 110% 110%;
    }
    0% {
      background-size: 100% 100%;
    }
  }
  